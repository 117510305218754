<template>
  <div class="h-24 p-3 mx-2.5 hover:bg-halfColonialWhite active:bg-halfColonialWhite hover:bg-opacity-10 active:bg-opacity-10 rounded"  :id="postArray.entity_id">
    <!--<a :href="postArray.link" class="cursor-pointer inline-block flex flex-none"> -->
    <a :href="linkAllowe ? postArray.link : null" class="cursor-pointer grid grid-cols-4">
      <div class="w-14 h-16 rounded-t-full rounded-b justify-self-center">
        <img :src="postArray.src" :alt="postArray.alt" class="mx-auto h-full overflow-hidden rounded-t-full rounded-b">
      </div>
      <div class="h-full col-start-2 col-end-4 pt-2">
        <h6 class="font-heading text-h6 text-sepiaBlack dark:text-white truncate">{{ postArray.title }}</h6>
        <p class="font-body text-bodyS text-sepiaBlack dark:text-halfColonialWhite dark:text-opacity-40">{{ postArray.era_date }}</p>
      </div>
      <p v-if="saveEnabel=='none'">
        <a href="#">
          <BaseIcon name="arrowRight" outline= false /> 
        </a>
      </p>
      <BaseIcon v-else-if="deletingElements.includes(postArray.entity_id)" name="checkmark" v-on:click="remove(postArray.entity_id)"/>
      <BaseIcon v-else name="checkmark" v-on:click="add(postArray.entity_id)" class="before-check" />
    </a>
  </div>
</template>

<script>

import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'EntityListMobile',
  components: {
    BaseIcon
  },
  props: {
    linkAllowe: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      require: true,
      default: () => {}
    },
    saveEnabel: {
      type: String,
      default: () => 'none',
    },
    deletingElements: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    postArray() {
      return {
        title: this.content?.title,
        text: this.content?.sport_content,
        src: this.content?.img,
        alt: this.content?.alt,
        link: this.content?.link,
        entity_id: this.content?.id,
        era_date: this.content?.era_date
      }
    }
  },
  methods: {
    add(entity_id) {
      this.$emit('addElementMobile', entity_id);
    },
    remove(entity_id) {
      this.$emit('removeElementMobile', entity_id);
    },
  },
}
</script>

<style>

.before-check img {
  display: none;
}
</style>