<template>
  <div class="flex justify-between mx-3 h-auto sticky top-0 z-50 sticy-header-background p-5">
    <a @click="this.$router.go(-1)" >
        <BaseIcon name= 'arrowLeft' />
    </a>
    <PinCode v-if="noPin ? false : true"/>
    <img v-bind:src="getLogo()" alt="Logo" class="h-14 static">
    <img :src="getFlag()" alt="language" class="absolute w-4 top-5 right-7">
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon.vue';
import PinCode from "@/components/PinCode.vue";

export default {
  name: 'MobileHeader',
  components: {
    BaseIcon,
    PinCode
  },
  props: {
    noPin: {
      type: Boolean,
      required: false
    },
    language: {
      type: String,
      default: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    },
  },
  methods: {
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        return require("../../assets/logo_dark.png");
      } else {
        return require("../../assets/logo_light.png");
      }
    },
    getFlag: function() {
      switch(this.language) {
        case 'en' :
          return require("../../assets/en.png");
        case 'hu' :
          return require("../../assets/hu.png");
        case 'de' :
          return require("../../assets/de.png");
        default:
          return require("../../assets/ro.png");
      }
    },
  },
   watch: {
    language: function() {
      this.getFlag();
    },
  },
}
</script>
<style>
.sticy-header-background{
  background: linear-gradient(180deg, #261710 0%, rgba(38, 23, 16, 0.67) 60.82%, rgba(38, 23, 16, 0) 100%);
}
</style>